import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import HeroMobile from "./../../images/Prostatizal-mobile.jpg"
import HeroTablet from "./../../images/Prostatizal-tablet.jpg"
import HeroDesktop from "./../../images/Prostatizal-desktop.jpg"
import HeroXL from "./../../images/Prostatizal-desktop-xl.jpg"
import Feature1 from "./../../images/prostatizal-feature.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"

const ProstatizalPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "ProstatizalEn" }}>
    <Seo title="Prostatizal" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>PROSTATIZAL</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Prostatizal"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Prostatizal
          </h5>
          <h1 className="display-4 font-light">For the prostate gland</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Prostatizal</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            For the prostate gland
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            The power of nature with proven herbs for prostate health.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/prostatizal-for-the-prostate-gland"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">When the prostate „calls“</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="When the prostate „calls“"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Prostatizal helps to strengthen and restore the activity of the
            prostate, having a positive effect on urinary function in difficult,
            frequent and painful urination. It is suitable as an aid in acute
            and chronic inflammation and adenoma of the prostate gland in the
            initial stage. Benefits for maintaining prostate health and
            improving sexual function.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality.
          </p>
          <p>
            Taking an extract with a proven effect ensures optimal impact on
            body functions. The plant extracts included in Prostatizal
            complement each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Small-flowered willow stalks</strong> (Herba
                    Epilodium parviflorium with.) 82,5 mg
                  </p>
                  <p>
                    <strong>Carnation stalks</strong> (Herba Eryngii campestris)
                    49,5 mg
                  </p>
                  <p>
                    <strong>Thunder Roots</strong> (Radix Ononidis) 49,5 mg
                  </p>
                  <p>
                    <strong>Hazelnut bark</strong> (Cortex Coryli avellanae)
                    49,5 mg
                  </p>
                  <p>
                    <strong>Goldenrod stalks</strong> (Herba Sulidaginis virago
                    aureae) 33,0 mg
                  </p>
                  <p>
                    <strong>Toadflax stalks</strong> (Herba linariae) 33,0 mg
                  </p>
                  <p>
                    <strong>Horsetail stalks</strong> (Herba Equiseti arvensis)
                    33,0 mg
                  </p>
                  <p>Excipients: maltodextrin, magnesium stearate</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 times daily, 1-2 tablets after meals</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Do not exceed the recommended daily dose.
                    Do not use the product as a substitute for a varied diet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Prostatizal is a natural product that has a beneficial
                    effect on the functions of the prostate gland. Helps
                    strengthen and restore the activity of the prostate, having
                    a positive effect on the excretory functions in difficult,
                    frequent and painful urination. Favors for maintaining
                    potency.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ProstatizalPageEn
